import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    public constructor(
        private storage: LocalStorageService
    ) { }

    public setItem(key: string, value: string): void {
        this.storage.store(key, value);
    }

    public setJson(key: string, value): void {
        try {
            this.storage.store(key, JSON.stringify(value));
        } catch (error) {
            console.error('error ', error);
        }
    }

    public getItem(key: string): string {
        return this.storage.retrieve(key);
    }

    public getJson(key: string) {
        try {
            const values = this.storage.retrieve(key);
            return JSON.parse(values);
        } catch (error) {
            console.error('error ', error);
            return null;
        }
    }

    public removeItem(key: string): void {
        return this.storage.clear(key);

    }
}
